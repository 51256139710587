import { CBCompositionRunner } from "../codebricks-runtime/CBCompositionRunner";
import { Brick, Composition, DebugLog } from "../codebricks-runtime/CBModels";

// let CodeBricks_compostion_template = document.createElement('template');
// CodeBricks_compostion_template.innerHTML = `<style>
//     .c-button { background-color: blue }
// </style>
// <slot></slot>`;
import Sugar from "sugar";
import { ObjectDeepMerge } from "../codebricks-runtime/CBUtil";
import { ExportFuncs } from "../shared-funcs/ExportFuncs";
import { setupInlineStyleProcessing } from "./controls/InlineStyleProcessor";
window.Sugar = Sugar; //Make Sugar avaliable inside ci-script-js

export class web_composition_reader extends HTMLElement {

    composition_id = '_';

    rtcn: CBCompositionRunner | undefined;

    constructor() {
        super();
    }
    async connectedCallback() {

        //@ts-expect-error This stops CKEDITOR from hijacking all contenteditables
        CKEDITOR.disableAutoInline = true;
        
        //let shadowRoot = this.attachShadow({ mode: 'open' });
        //shadowRoot.appendChild(CodeBricks_compostion_template.content.cloneNode(true));
        this.innerHTML = `<div></div>`;

        let composition = this.getAttribute('composition');

        let self = this;

        let urlParams = this.getUrlParams();

        let compos = (<any>window).codebricks as Composition;

        let context = (globalThis as any).codebricks_context;

        if(context) {
            delete context.compositions[self.composition_id];
        }

        (<any>globalThis).codebricks_context = (<any>globalThis).codebricks_context || {};

        let logevents = sessionStorage.getItem("logevents");
        if(logevents) {
            (<any>globalThis).codebricks_context.logevents = true;
        }

        (<any>window).autoid = (<any>window).autoid || 0;

        self.rtcn = new CBCompositionRunner(composition as string, (<any>globalThis).codebricks_context,
            // async function() {
            //     return await CBWebUtil.GetRequest("/cb_api/admin/debug_log/"+composition);
            // },
            // async function(led: DebugLog) {
            //     return await CBWebUtil.PostRequest("/cb_api/admin/debug_log/"+composition, led);
            // },
            //@ts-expect-error
            undefined
        );

        
        (<any>window).enable_debugging = function() : void {
            if(sessionStorage) {
                sessionStorage.setItem("enable_debugging", "yes");
                if(self.rtcn) {
                    self.rtcn.debugging_enabled = true;
                }
                console.log("debugging enabled");
            }
        }

        compos.ins = compos.ins || {};

        self.rtcn.debugging_enabled = sessionStorage.getItem("enable_debugging") == "yes";
        self.rtcn.context.platform = compos.platform || "web";
        if(compos.snapshot && compos.snapshot.snapshot) {
            ExportFuncs.ApplyBrickSnapshots(compos as Brick, compos.snapshot.snapshot);
            let snapshot_id = urlParams.snapshot_id;
            let slug = urlParams.slug;
            urlParams = compos.ins.args || {};
            if(snapshot_id) {
                urlParams.snapshot_id = snapshot_id;
            }
            if(slug) {
                urlParams.slug = slug;
            }
        }
        self.rtcn.context.timezone_offset = new Date().getTimezoneOffset();
        if(compos.snapshot && compos.snapshot.timezone_offset !== undefined) {
            self.rtcn.context.timezone_offset = compos.snapshot.timezone_offset;
            compos.system_options.timezone_offset = compos.snapshot.timezone_offset;
        }

        

        //Also have to params availble as io.param, not just io.@args.param
        compos.ins.default = compos.ins.default || {};
        ObjectDeepMerge(compos.ins.default, urlParams);

        setupInlineStyleProcessing("inline-class");

        self.rtcn.init_composition(compos, urlParams);

        compos.ins.args = compos.ins.args || {};
        let home_slug = compos.ins.args.slug;
        ObjectDeepMerge(compos.ins.args, urlParams);
        if(home_slug) {
            compos.ins.args.slug = home_slug; //The home page is / so has no slug in url so it was set here in app.js
        }

        let slot = self.querySelector('div');
        if(slot) {
            slot.innerHTML = `<${compos.type} cid="${self.rtcn.cid}" name="${compos.name}"></${compos.type}>`;
        }

        if(window.localStorage.getItem("cb_locale")) {
            compos.system_options.locale = window.localStorage.getItem("cb_locale");
        }
        
        await self.rtcn.send_initialisation_events(null);

        console.log(`Total Processing time: ${(<any>window).totalProcessNode}ms`);

        console.log(`Total Inject Styles time: ${(<any>window).totalInjectStyles}ms`);
    
    }

    getUrlParams() {
        let urlParams = {} as { [key:string]: string };
        let match,
        pl = /\+/g,  // Regex for replacing addition symbol with a space
        search = /([^&=]+)=?([^&]*)/g,
        decode = function (s: string) {
            return decodeURIComponent(s.replace(pl, " "));
        },
        query = window.location.search.substring(1);

        while (match = search.exec(query)) {
            if (decode(match[1]) in urlParams) {
                if (!Array.isArray(urlParams[decode(match[1])])) {
                    (<any>urlParams)[decode(match[1])] = [urlParams[decode(match[1])]];
                }
                (<any>urlParams[decode(match[1])]).push(decode(match[2]));
            } else {
                urlParams[decode(match[1])] = decode(match[2]);
            }
        }

        if(!urlParams.slug) {
            let s = window.location.pathname.split("/");
            let slug = s[s.length-1];
            urlParams.slug = slug;
        }

        return urlParams;
    }

    disconnectedCallback() {
        //console.log("disconnectedCallback");
        // let cbglobal = <CBGlobal>(<any>globalThis).cbglobal;

        // delete cbglobal.compositions[this.composition_id];

        // if(this.parentNode) {
        //     this.parentNode.removeChild(this);
        // }

        // alert("disconnectedCallback");

    }
      
    // attributeChangedCallback(name, oldValue, newValue) {
    //     console.log("attributeChangedCallback");
    // }
      
    adoptedCallback() {
        //console.log("adoptedCallback");
    }
}

customElements.define('cb-composition-reader', web_composition_reader);

// (function() {
//     console.log("HELLO");
// })()